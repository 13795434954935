<div class="d-flex justify-content-between">
  <div>
    <h2 mat-dialog-title>{{ data.title }}</h2>
  </div>
  <div class="control-button">
    <button mat-button mat-dialog-close type="button" class="btn btn-sm  btn-outline-primary">
      <i class="ti ti-circle-x"></i>
    </button>
    <button type="button" class="btn btn-sm  btn-outline-primary">
      <i class="ti ti-device-floppy" (click)="save()"></i>
    </button>
  </div>
</div>
<mat-dialog-content class="mat-typography">
  <form [formGroup]="form">
    <div class="row">
      <div class="col-md-6">
        <label>Name </label>
        <div class="col-md-12">
          <input type="text" formControlName="name" placeholder="Enter Name" class="form-control">
        </div>
      </div>
      <div class="col-md-6">
        <label>Selection </label>
        <br>
        <div class="form-check form-check-inline">
          <label class="form-check-label">Yes
            <input class="form-check-input" type="checkbox" formControlName="selected_yes">
          </label>
        </div>
        <div class="form-check form-check-inline">
          <label class="form-check-label">
            <input class="form-check-input" type="checkbox" formControlName="selected_no">
            No</label>
        </div>
      </div>
      <div class="col-md-6">
        <label>Date </label>
        <div class="col-lg-12">
          <input type="text" formControlName="date" placeholder="Enter Date" class="form-control">
        </div>
      </div>
      <div class="col-md-6">
        <label >Remarks </label>
        <div class="col-lg-12">
          <textarea class="form-control" rows="1" formControlName="remarks"></textarea>
        </div>
      </div>
    </div>
  </form>
</mat-dialog-content>
