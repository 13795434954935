import {Component, Inject, OnInit} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';

@Component({
  selector: 'app-microvascular-screening',
  templateUrl: './microvascular-screening.component.html',
  styleUrls: ['./microvascular-screening.component.scss']
})
export class MicrovascularScreeningComponent implements OnInit {

  constructor(
    private matDialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: { type: string, title: string }
  ) { }

  form = new FormGroup({
    selected_yes: new FormControl(false),
    selected_no: new FormControl(false),
    name: new FormControl(),
    date: new FormControl(),
    remarks: new FormControl(),
  })

  ngOnInit(): void {
  }

  save() {
    const input = this.form.value;
    input.is_default = false;
    this.matDialogRef.close(input);
  }
}
